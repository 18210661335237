import React, { useEffect, useState, useMemo } from 'react'
import { Col, Row, Popover, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { parse, stringify } from 'query-string'
import dayjs from 'dayjs'
import { makeSelectCurrentUser } from 'container/Auth/selectos'

import images from 'Themes/Images'
import config from 'src/global-config'
import ListUser from './components/ListUser'
import useComponentVisible from 'utils/hooks'
import BtnPrimary from 'component/BtnPrimary'
import AssessmentRecord from '../Reports/AssessmentRecord'
import AddOutcomeCourse from './components/AddOutcomeCourse'
import TableLearningOutCome from './components/TableLearningOutCome'
import HeaderCourseView from '../../screens/CourseView/HeaderCourseView'
import {
  makeSelectCourse,
  makeSelectSlidesOriginal,
  makeSelectDomain,
} from 'container/Home/selectors'
import { loadCourse, loadSlides } from 'container/Home/actions'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import {
  mapScheduleToDropDown,
  getListLearningOutCome,
  mapListLearningOutcomeUser,
} from 'utils/helper'
import { showMarkingOutComeModal } from 'container/Modal/actions'
import { showError } from 'utils/notification'
import './styles.scss'
import { DOMAIN } from 'src/routes'
import { uploadImage } from '../../../CMS/hooks'
import { hideGlobalLoading, showGlobalLoading } from '../../../Modal/actions'
import { getResponseData } from '../../../../utils/helper'
import axiosInstance from '../../../../utils/axiosInstance'

const OutComeReviews = () => {
  const dispatch = useDispatch()
  const [switchTab, setSwitchTab] = useState(false)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [userStatus, setUserStatus] = useState(null)
  const [listUserStatus, setListUserStatus] = useState([])
  const course = useSelector(makeSelectCourse())
  const courseName = course?.name || ''
  const proficieny_name = get(course, 'proficieny_name', '')
  const proficieny_id = get(course, 'proficieny_id', '')
  const history = useHistory()
  const { pathname, search } = useLocation()
  const parsedSearch = parse(search)
  const requiredSchedule = get(course, 'is_schedule', false)
  const [remarks, setRemarks] = useState([])
  const schedules = useSelector(selectors.makeSelectSchedules())
  const slidesOriginal = useSelector(makeSelectSlidesOriginal())
  const listUserByCourse = useSelector(selectors.makeSelectListUserByCourse())
  const [userProfile, setUserProfile] = useState(null)
  const adminUser = useSelector(makeSelectCurrentUser())

  const domain = useSelector(makeSelectDomain())
  const [signatureStudentName, setSignatureStudentName] = useState('')

  const [signatureTrainerName, setSignatureTrainerName] = useState('')

  const fetchProfiles = async (userId) => {
    try {
      const res = await axiosInstance.get(`/api/users/${userId}/profiles`)

      const data = getResponseData(res)
      if (data?.length > 0) {
        setUserProfile(data?.[0])
      } else {
        setUserProfile(adminUser)
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  useEffect(() => {
    if (userStatus) {
      fetchProfiles(userStatus?.create_uid)
      setSignatureStudentName(userStatus?.signature_student_name || '')
      setSignatureTrainerName(userStatus?.signature_trainer_name || '')
    } else {
      setUserProfile(adminUser)
      setSignatureStudentName('')
      setSignatureTrainerName('')
    }
  }, [userStatus, adminUser])

  const isUnicornOrBell =
    get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION ||
    get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN
  const isUnicornOrBellOrAscendo =
    get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION ||
    get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN ||
    get(domain, 'domain', '') === DOMAIN.ASCENDO ||
    get(domain, 'domain', '') === DOMAIN.ASCENDO_2

  const isUnicornDomainOnly =
    get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN

  const isBellDomainOnly = get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION
  const isEmptyOrSpaces = (str) =>
    str === null || str?.match(/^ *$/) !== null || /^\s*$|^[\r\n]*$/.test(str)

  // assessment record
  const listSetOutcomeStudents = useSelector(
    selectors.makeSelectListSetOutcomeStudents()
  )

  // user learning outcome
  const learningOutcomeUser = useSelector(
    selectors.makeSelectLearningOutComeByUser()
  )
  // user custom learning outcome
  const listCustomOutcomeUser = useSelector(
    selectors.makeSelectListCustomOutcomeStudent()
  )

  const listCustomOutcomeUserIDs = useMemo(
    () =>
      (Array.isArray(listCustomOutcomeUser) &&
        listCustomOutcomeUser?.map((item) => item.id)) ||
      [],
    [listCustomOutcomeUser]
  )
  // list set out custom learning outcome
  const listSetOutcomeCustom = useSelector(
    selectors.makeSelectListSetOutcomeCustom()
  )

  // get list learning out come
  const listLearningOutCome = useMemo(
    () => getListLearningOutCome(slidesOriginal),
    [slidesOriginal]
  )

  // New Set

  const listSetOutcomCustomByUser = useMemo(
    () =>
      Array.isArray(listSetOutcomeCustom) &&
      listSetOutcomeCustom.filter(
        (item) => item.user_id === get(currentUser, 'user.id')
      ),
    [listSetOutcomeCustom]
  )

  const learningOutcomeUserMap = useMemo(
    () => mapListLearningOutcomeUser(listLearningOutCome, learningOutcomeUser),
    [listLearningOutCome, learningOutcomeUser]
  )

  const mergeListLearningOutcomeUser = useMemo(
    () =>
      Array.isArray(learningOutcomeUserMap) &&
      Array.isArray(listSetOutcomCustomByUser) && [
        ...learningOutcomeUserMap,
        ...listSetOutcomCustomByUser,
      ],
    [learningOutcomeUserMap, listSetOutcomCustomByUser]
  )
  const countCharacters = (str) => {
    const cleanedStr = str.replace(/\s/g, '')
    return cleanedStr.length
  }
  const isValidRemarks = useMemo(
    () =>
      Array.isArray(mergeListLearningOutcomeUser) &&
      Array.isArray(listCustomOutcomeUserIDs) &&
      mergeListLearningOutcomeUser?.length &&
      mergeListLearningOutcomeUser?.every(
        (item) =>
          !listCustomOutcomeUserIDs?.includes(
            item?.learning_outcome_channel_custom_id
          ) ||
          (!isEmptyOrSpaces(
            remarks?.find((ite) => ite?.id === item?.id)?.remark
          ) &&
            countCharacters(
              remarks?.find((ite) => ite?.id === item?.id)?.remark
            ) >= 3)
      ),
    [mergeListLearningOutcomeUser, listCustomOutcomeUserIDs, remarks]
  )

  const listOutcome = useSelector(selectors.makeSelectListOutcome())
  const initialSelectSchedule = mapScheduleToDropDown(
    schedules,
    false,
    false,
    true
  )
  const { id } = useParams()

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  useEffect(() => {
    if (listSetOutcomeCustom && listSetOutcomeStudents) {
      setRemarks([...listSetOutcomeStudents, ...listSetOutcomeCustom])
    }
  }, [listSetOutcomeCustom, listSetOutcomeStudents])

  useEffect(() => {
    if (Array.isArray(schedules) && parsedSearch && parsedSearch.schedule) {
      const schedule = schedules.find(
        (item) => item.id === parseInt(parsedSearch.schedule)
      )
      if (schedule) {
        const id = get(schedule, 'id')
        const classCode = get(schedule, 'class_code', '')
        const startDate = get(schedule, 'start_date', '')
        const endDate = get(schedule, 'end_date', '')
        let label = `(${classCode}) ${dayjs(startDate).format(
          'DD MMM'
        )} - ${dayjs(endDate).format('DD MMM YYYY')}`
        const duration = dayjs(endDate).diff(startDate, 'year')
        if (duration === 10) {
          label = `(${classCode}) Always Available`
        }
        setSelectedSchedule({ value: id, label, class_code: classCode })
      }
    }
  }, [parsedSearch.schedule, schedules])

  useEffect(() => {
    if (id) {
      const isAdmin = true
      dispatch(loadCourse(id))
      dispatch(actions.getSchedules(id, isAdmin, null, false, true))
    }
  }, [id])

  useEffect(() => {
    dispatch(actions.loadListLearningOutcome())
  }, [])

  useEffect(() => {
    if (parsedSearch && parsedSearch.user && Array.isArray(listUserByCourse)) {
      const user = listUserByCourse.find(
        (item) => item.id === parseInt(parsedSearch.user)
      )

      setCurrentUser(user)
    }
  }, [parsedSearch.user, listUserByCourse])

  useEffect(() => {
    if (Array.isArray(listUserByCourse) && listUserByCourse.length > 0) {
      const listUid = listUserByCourse.reduce(
        (acc, curr) =>
          curr.user && curr.user.id
            ? acc + `&user_ids=${get(curr, 'user.id', '')}`
            : acc,
        ''
      )

      dispatch(actions.getListSetOutcomeStudents({ channel_id: id, listUid }))
    }
  }, [listUserByCourse])

  useEffect(() => {
    if (id) {
      dispatch(
        actions.getUserByCourseAndSchedule({ id, schedule: selectedSchedule })
      )
      dispatch(loadSlides(id, false, null, get(selectedSchedule, 'value', '')))
    }
  }, [id, selectedSchedule])

  useEffect(() => {
    if (currentUser && id) {
      const channel_id = id
      const user_id = get(currentUser, 'user.id', '')
      const params = {
        channel_id,
        user_id,
      }

      dispatch(actions.getLearningOutComeByUser(params))
      dispatch(actions.getListSetOutcomeCustom(params))
      dispatch(actions.getListOutcomeStudent(params))
    }
  }, [currentUser, id])

  useEffect(() => {
    const getUserStatus = async (userId) => {
      try {
        const requestUrl = `${config.baseUrl}/api/learning-outcome/channels/users/${userId}`
        const res = await fetch(requestUrl, { method: 'GET' })
        const data = await res.json()

        if (Array.isArray(data.data)) {
          const status = data.data.find((item) => item.channel_id === +id)
          setUserStatus(status)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    if (currentUser && id) {
      getUserStatus(get(currentUser, 'user.id', ''))
    }
  }, [currentUser, id])

  const handleSelectUser = (item) => {
    const queryParams = stringify({
      schedule: parsedSearch.schedule,
      user: item.id,
    })
    history.push({ pathname: pathname, search: queryParams })
  }
  const base64ToBlob = (base64, mime) => {
    // Decode Base64 string
    const binary = atob(base64)
    const arrayBuffer = new ArrayBuffer(binary.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    // Convert binary string to Uint8Array
    for (let i = 0; i < binary.length; i++) {
      uint8Array[i] = binary.charCodeAt(i)
    }

    // Create Blob from Uint8Array
    return new Blob([uint8Array], { type: mime })
  }
  const handleClickMark = async () => {
    dispatch(showGlobalLoading())
    if (
      !Array.isArray(listLearningOutCome) ||
      !Array.isArray(listCustomOutcomeUser)
    ) {
      dispatch(hideGlobalLoading())
      return showError('Something went wrong! please refresh your page.')
    }
    const allOutComeLength =
      listLearningOutCome.length + listCustomOutcomeUser.length

    if (mergeListLearningOutcomeUser.length < allOutComeLength) {
      dispatch(hideGlobalLoading())
      return showError('Please grade all learning outcomes')
    }

    if (!isValidRemarks) {
      dispatch(hideGlobalLoading())
      return showError('All remarks must be filled in')
    }
    if (!signatureStudentName && isBellDomainOnly) {
      dispatch(hideGlobalLoading())
      return showError("Please sign student's name!")
    }
    if (!signatureTrainerName && isBellDomainOnly) {
      dispatch(hideGlobalLoading())
      return showError("Please sign trainer's name!")
    }
    let signature = {
      student: '',
      trainer: '',
      signStudentDate: '',
      signTrainerDate: '',
    }
    if (signatureStudentName?.startsWith('data:image/png;base64,')) {
      const base64Str = signatureStudentName.split(',')[1]

      const blob = base64ToBlob(base64Str, 'image/png')

      const file = new File(
        [blob],
        `studentName_${dayjs().format('YYMMDD-HHmmss')}.png`,
        { type: 'image/png' }
      )

      const signatureStudent = await uploadImage(file)
      signature.student = signatureStudent?.url
      signature.signStudentDate = dayjs()
    } else {
      signature.student = signatureStudentName
      signature.signStudentDate = userStatus?.signature_student_date || null
    }
    if (signatureTrainerName?.startsWith('data:image/png;base64,')) {
      const base64Str = signatureTrainerName.split(',')[1]

      const blob = base64ToBlob(base64Str, 'image/png')

      const file = new File(
        [blob],
        `trainerName_${dayjs().format('YYMMDD-HHmmss')}.png`,
        { type: 'image/png' }
      )

      const signatureStudent = await uploadImage(file)
      signature.trainer = signatureStudent?.url
      signature.signTrainerDate = dayjs()
    } else {
      signature.trainer = signatureTrainerName
      signature.signTrainerDate = userStatus?.signature_trainer_date || null
    }

    for (let i = 0; i < mergeListLearningOutcomeUser?.length; i++) {
      const itemIndex = remarks?.findIndex(
        (item) => item?.id === mergeListLearningOutcomeUser[i].id
      )

      if (itemIndex !== -1) {
        mergeListLearningOutcomeUser[i].remark = remarks[itemIndex].remark
      }
    }

    if (learningOutcomeUser) {
      dispatch(hideGlobalLoading())
      dispatch(
        showMarkingOutComeModal({
          mergeListLearningOutcomeUser,
          currentUser,
          listLearningOutCome,
          userStatus,
          channel_id: id,
          selectedSchedule,
          signature,
        })
      )
    }
  }

  const competent = get(userStatus, 'competent', null)
  return (
    <div>
      <HeaderCourseView course={course} />
      <div className="oc-views">
        <div className="channel" style={{ margin: 0, height: 720 }}>
          <div className="channel__head" style={{ zIndex: 200 }}>
            <div className="channel__head__left">
              <div className="channel__chapter">
                COURSE: {get(course, 'name', '')}
              </div>
              <div className="channel__title">Learning Outcome Assessment</div>
            </div>
            <div
              className="channel__head__right"
              style={{ alignItems: 'center' }}
            >
              <div
                className="channel__head__right__button"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {listUserByCourse &&
                  listSetOutcomeStudents &&
                  listLearningOutCome &&
                  listCustomOutcomeUser &&
                  listSetOutcomeCustom &&
                  slidesOriginal && (
                    <div style={{ marginRight: 20 }}>
                      <Popover
                        placement="bottomLeft"
                        overlayClassName="popup_confirm"
                        content={
                          <div className="popup-cta">
                            <AssessmentRecord
                              selectedSchedule={selectedSchedule}
                              slidesOriginal={slidesOriginal}
                              proficieny_name={proficieny_name}
                              proficieny_id={proficieny_id}
                              listLearningOutCome={listLearningOutCome}
                              listSetOutcomeStudents={listSetOutcomeStudents}
                              listUserByCourse={listUserByCourse}
                              listUserStatus={listUserStatus}
                              listCustomOutcomeUser={listCustomOutcomeUser}
                              listSetOutcomeCustom={listSetOutcomeCustom}
                              courseName={courseName}
                              course={course}
                            />
                          </div>
                        }
                        trigger="click"
                        visible={isComponentVisible}
                      >
                        <BtnPrimary
                          btnRef={ref}
                          name="Export Report"
                          icon={images.admin.download}
                          style={{
                            color: '#042580',
                            backgroundColor: '#f6f6f9',
                          }}
                          handleClick={() =>
                            setIsComponentVisible(!isComponentVisible)
                          }
                          type="button"
                        />
                      </Popover>
                    </div>
                  )}
                {requiredSchedule && (
                  <>
                    <div
                      style={{
                        marginRight: 15,
                        whiteSpace: 'nowrap',
                        fontWeight: '600',
                        fontSize: 12,
                        color: '#27284D',
                      }}
                    >
                      Select batch
                    </div>
                    <div style={{ minWidth: 360 }}>
                      {initialSelectSchedule && (
                        <Select
                          className="selectSchedule"
                          showSearch
                          placeholder="Select a schedule"
                          optionFilterProp="children"
                          onChange={(value) => {
                            history.push(
                              pathname + `?quiz=true&schedule=${value}`
                            )
                          }}
                          value={selectedSchedule}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={initialSelectSchedule}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="quiz__body-3 quiz__border-top">
            <Row style={{ width: '100%' }}>
              <Col xs={6} md={6}>
                <div className="quiz__left-2">
                  <div className="quiz__question">
                    <div className="quiz__summary">
                      <div
                        className={`quiz__summary__section ${
                          !switchTab ? 'quiz__summary__section-active' : ''
                        }`}
                        onClick={() => setSwitchTab(false)}
                      >
                        {listUserByCourse ? (
                          <div className="quiz__summary__number">
                            {listUserByCourse.length}
                          </div>
                        ) : (
                          <div className="quiz__summary__number">0</div>
                        )}

                        <div className="quiz__summary__label">All students</div>
                      </div>
                    </div>
                  </div>
                  <div className="quiz__list-user-2">
                    {listUserByCourse &&
                      listUserByCourse.map((item) => {
                        return (
                          item.user && (
                            <ListUser
                              id={id}
                              key={item.id}
                              item={item}
                              currentUser={currentUser}
                              handleSelectUser={handleSelectUser}
                              userStatus={userStatus}
                              listUserStatus={listUserStatus}
                              setListUserStatus={setListUserStatus}
                            />
                          )
                        )
                      })}
                  </div>
                </div>
              </Col>

              <Col xs={18} md={18}>
                <div
                  className="quiz__right-2"
                  style={{ borderLeft: '1px solid #d0cad8' }}
                >
                  <div className="quiz__right-answer-2">
                    {currentUser &&
                      listLearningOutCome &&
                      listOutcome &&
                      learningOutcomeUser &&
                      listCustomOutcomeUser &&
                      listSetOutcomeCustom &&
                      listUserByCourse?.length > 0 && (
                        <>
                          <TableLearningOutCome
                            id={id}
                            currentUser={currentUser}
                            listOutcome={listOutcome}
                            selectedSchedule={selectedSchedule}
                            learningOutcome={listLearningOutCome}
                            learningOutcomeUser={learningOutcomeUser}
                            listSetOutcomeCustom={listSetOutcomeCustom}
                            listCustomOutcomeUser={listCustomOutcomeUser}
                            remarks={remarks}
                            setRemarks={setRemarks}
                            isUnicornOrBell={isUnicornOrBell}
                            isUnicornDomainOnly={isUnicornDomainOnly}
                          />
                          <AddOutcomeCourse
                            id={id}
                            dispatch={dispatch}
                            currentUser={
                              listUserByCourse?.length > 0 ? currentUser : null
                            }
                            course={course}
                            selectedSchedule={selectedSchedule}
                            listSetOutcomeCustom={listSetOutcomeCustom}
                            learningOutcome={listLearningOutCome}
                            listCustomOutcomeUser={listCustomOutcomeUser}
                            isUnicornOrBellOrAscendo={isUnicornOrBellOrAscendo}
                            setSignatureTrainerName={setSignatureTrainerName}
                            setSignatureStudentName={setSignatureStudentName}
                            signatureStudentName={signatureStudentName}
                            signatureTrainerName={signatureTrainerName}
                            userProfile={userProfile}
                            isBellDomainOnly={isBellDomainOnly}
                          />
                        </>
                      )}
                    <div style={{ marginBottom: 150 }}></div>
                  </div>
                  {currentUser && (
                    <div className="quiz__right__bottom">
                      <BtnPrimary
                        style={
                          !competent && isValidRemarks
                            ? {
                                padding: '12px 20px',
                              }
                            : {
                                backgroundColor: '#B3B8BF',
                                padding: '12px 20px',
                              }
                        }
                        disabled={!competent && !isValidRemarks}
                        type="button"
                        handleClick={handleClickMark}
                        name={competent !== null ? 'Submitted' : 'Submit marks'}
                        icon={
                          competent !== null
                            ? images.header.check_inactive
                            : images.header.check
                        }
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OutComeReviews
